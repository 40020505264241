.App {
  text-align: center;
}

.input-container {
  width: 90%;
  padding-left: 10%;
  margin-top: 5px;
}
.form-group {
  margin-bottom: 5px;
}


.form-control:focus {
  
  border-color: #fcb539;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
  
}

input {
  margin-top: 2px;
}
span{
  margin: 2px;
  margin-top: 5px;
}

select {
  margin-top: 5px;
}
.form-check {
  font-size: 12px;
}
h3 {
  text-align: left;
  padding-left: 20px;
  color: rgb(72, 104, 107);
}
.Center {
  position: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 90%;
}
.jumboContent .Center {
  max-width: 350px;
}
.jumbo {
  display: block;
  position: relative;
  text-align: center;
  margin: 10px;
  
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: auto;
}





.btn-outline-primary{
  font-size: 12px;
}

.btn-sm {
  margin-right: 0px;
  margin-top: -5px;
  float: right;

  position: relative;
  border: solid 0px rgb(92, 213, 243);
  border-top-right-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.jumboTitle {
  min-height: 32px;
  position: relative;
  font-family: 'Arial Narrow';
  color: rgb(96, 98, 105);
  padding-top: 5px;
  padding-left: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: flex-start;
  background-color: rgb(220, 220, 220);
  opacity: 1;
  border: solid 2px rgb(57, 57, 57);
}

.jumboTitleContent {
  margin-right: 50px;
}
.jumboContent {
  font-size: 14px;
  border: solid 2px rgb(57, 57, 57);
  font-family: 'Arial Narrow';
  color: rgb(96, 98, 105);
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0.418);
  padding-bottom: 10px;
}

.App-logo {
  margin-top: 20px;
  height: 50px;
}

.App-header {
  background-color: rgb(54, 53, 53);
  height: 160px;
  padding: 20px;
  color: white;
}

.App-title {
  margin-top: 20px;
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
